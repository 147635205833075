.video-container {
    position: relative;
    width: 100%; /* Adjust as needed */
    max-width: 100%; /* Ensures video does not overflow */
    height: 0;
    padding-top: 56.25%; /* for 16:9 aspect ratio, adjust as needed */
    overflow: hidden;
  }
  
  .video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* or 'contain' based on your requirement */
  }  